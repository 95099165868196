import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaThumbsUp } from "react-icons/fa";

const AgentTargets = ({ serviceCounts, jobCounts }) => {

  console.log("Agent widget service counts (prop): ", serviceCounts)
  console.log("Agent job counts (prop): ", jobCounts)

  const totalWonChips = Math.ceil(Number(serviceCounts.total_chips) || 0);
  const totalSurveyed = Math.ceil(Number(serviceCounts.total_surveyed_cars) || 0);
  const [targets, setTargets] = useState({
    target1: 0,
    target2: 0,
    target3: 0,
  });

  useEffect(() => {
    setTargets({
      target1: Math.ceil(totalSurveyed * 0.3 * 1.8),
      target2: Math.ceil(totalSurveyed * 0.35 * 1.8),
      target3: Math.ceil(totalSurveyed * 0.4 * 1.8),
    });
  }, [totalSurveyed]);

  const achievedTargets = [
    totalWonChips >= targets.target1,
    totalWonChips >= targets.target2,
    totalWonChips >= targets.target3,
  ];

  return (
    <div className="widget-report">
      <Link to="/pipeline">
        <div className="widget">
          <div className="widget-text">
            <h3>Surveyed</h3>
            <h4>{totalSurveyed ?? 0}</h4>
          </div>
        </div>
      </Link>

      <Link to="/pipeline">
        <div className="widget">
          <div className="widget-text">
            <h3>Pending</h3>
            <h4>{jobCounts.pendingDeals ?? 0}</h4>
          </div>
        </div>
      </Link>

      <div className="chips-targets-cards">
        {/* Target 1 */}
        <div className={`widget`}>
          <div className="widget-text">
            <h3>Chips Target 1</h3>
            {achievedTargets[0] ? (
              <FaThumbsUp className="widget-thumb-icon" size={34} color="green" />
            ) : (
              <h4>{totalWonChips} / {targets.target1}</h4>
            )}
          </div>
        </div>

        {/* Target 2 - Only show if Target 1 achieved */}
        <div className={`widget ${achievedTargets[0] ? "" : "disabled"}`}>
          <div className="widget-text">
            <h3>Chips Target 2</h3>
            {achievedTargets[1] ? (
              <FaThumbsUp size={24} color="green" />
            ) : (
              <h4>{totalWonChips} / {targets.target2}</h4>
            )}
          </div>
        </div>

        {/* Target 3 - Only show if Target 2 achieved */}
        <div className={`widget ${achievedTargets[1] ? "" : "disabled"}`}>
          <div className="widget-text">
            <h3>Chips Target 3</h3>
            {achievedTargets[2] ? (
              <FaThumbsUp size={24} color="green" />
            ) : (
              <h4>{totalWonChips} / {targets.target3}</h4>
            )}
          </div>
        </div>
      </div>

      <div className="widget">
        <div className="widget-text">
          <h3>HL Won</h3>
          <h4>{serviceCounts.total_headlight_repairs ?? 0}</h4>
        </div>
      </div>

      <div className="widget">
        <div className="widget-text">
          <h3>PP Won</h3>
          <h4>{serviceCounts.total_polish_jobs ?? 0}</h4>
        </div>
      </div>
    </div>
  );
};

export default AgentTargets;

import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { format, addDays, setHours, setMinutes, setSeconds } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";

const RangeDatePicker = ({ setDateRange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 0));

  const handleDateChange = ([start, end]) => {
    setStartDate(start);

    // Always set the end date to 23:59:59 if an end date is selected
    if (end) {
      end = setHours(setMinutes(setSeconds(end, 59), 59), 23);
    }

    setEndDate(end);

    // Ensure both start and end dates are defined before updating
    if (start && end) {
      const formattedStart = format(start, "yyyy-MM-dd HH:mm:ss");
      const formattedEnd = format(end, "yyyy-MM-dd HH:mm:ss");
      // Update the dateRange in the parent component
      setDateRange({
        startDate: formattedStart,
        endDate: formattedEnd,
      });
    }
  };

  return (
    <div className="date-picker-section">
      <DatePicker
        className="datepicker-field"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        dateFormat="yyyy-MM-dd"
        isClearable={true} // Optional: Allows the user to clear the date range
      />
    </div>
  );
};

export default RangeDatePicker;

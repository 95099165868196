import React, { useState, useEffect } from "react";
import "./StatusFilter.css";
import { createVehicleLogEntry } from "../../utils/createVehicleLogEntry";
import { createNotification } from "../../utils/createNotification";

const StatusFilter = ({
  onInsuranceSelect,
  onStatusChange,
  registrationNumber,
  userId,
  jobId,
  clientType,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  // Set the selected option when the component mounts or clientType changes
  useEffect(() => {
    console.log('Client Type: ', clientType)
    if (clientType) {
      setSelectedOption(clientType);
    }
  }, [clientType]);

  const logStatusChange = async (newStatus) => {
    const logData = {
      registration_number: registrationNumber,
      user_id: userId,
      activity_title: "Status Change",
      activity_description: `Status changed to ${newStatus}`,
    };

    try {
      await createVehicleLogEntry(logData);
    } catch (error) {
      console.error("Error logging status change:", error);
    }
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);

    if (["Account", "EFT", "Insurance"].includes(option)) {
      if (option === "Insurance" && onInsuranceSelect) {
        onInsuranceSelect();
      } else {
        const clientType = option;
        onStatusChange(option, null, clientType);
        logStatusChange(option);
      }
    } else if (option === "DND") {
      handleDNDSelect();
    } else if (["DNC", "No", "Pending"].includes(option)) {
      onStatusChange(option);
      logStatusChange(option);
    } else {
      onStatusChange("Pending", option);
    }
  };

  const handleDNDSelect = () => {
    setShowModal(true);
    setIsDropdownOpen(false);
    setSelectedOption("DND");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNotificationMessage("");
  };

  const handleNotificationSubmit = async () => {
    const notificationData = {
      sender_id: userId,
      recipient_id: 3,
      notification_title: "DND Approval Required",
      notification_content: `Job ID: ${jobId} - Registration number: ${registrationNumber} - ${notificationMessage}`,
      notification_status: "unread",
    };

    try {
      await onStatusChange("DND");
      await logStatusChange("DND");

      await createNotification(notificationData);
      console.log("DND notification sent.");
    } catch (error) {
      console.error("Error creating notification:", error);
    }

    handleCloseModal();
  };

  return (
    <div className="status-filter">
      <h2>Status</h2>
      <div className="status-filters">
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "Account" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("Account")}
        >
          Account
        </a>
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "Insurance" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("Insurance")}
        >
          Insurance
        </a>
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "EFT" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("EFT")}
        >
          EFT
        </a>
        <div
          className="status-filter-dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <a
            href="#"
            className={`status-filter-link ${
              selectedOption === "Pending" ? "active" : ""
            }`}
          >
            Pending
          </a>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <a
                href="#"
                className={`dropdown-item ${
                  selectedOption === "NA" ? "active" : ""
                }`}
                onClick={() => handleOptionSelect("NA")}
              >
                NA
              </a>
              <a
                href="#"
                className={`dropdown-item ${
                  selectedOption === "TCB" ? "active" : ""
                }`}
                onClick={() => handleOptionSelect("TCB")}
              >
                TCB
              </a>
              <a
                href="#"
                className={`dropdown-item ${
                  selectedOption === "WN" ? "active" : ""
                }`}
                onClick={() => handleOptionSelect("WN")}
              >
                WN
              </a>
            </div>
          )}
        </div>
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "DND" ? "active" : ""
          }`}
          onClick={handleDNDSelect}
        >
          DND
        </a>
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "DNC" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("DNC")}
        >
          DNC
        </a>
        <a
          href="#"
          className={`status-filter-link ${
            selectedOption === "No" ? "active" : ""
          }`}
          onClick={() => handleOptionSelect("No")}
        >
          No
        </a>
      </div>

      {showModal && (
        <div className="dnd-modal">
          <div className="dnd-modal-content">
            <h3 className="dnd-h3">DND Notification</h3>
            <p>
              <strong>Registration Number:</strong>{" "}
              <em>{registrationNumber}</em>
            </p>
            <p>
              <strong>Job ID:</strong> <em>{jobId}</em>
            </p>
            <textarea
              className="dnd-textarea"
              placeholder="Enter a message for the notification..."
              value={notificationMessage}
              onChange={(e) => setNotificationMessage(e.target.value)}
            />
            <div className="dnd-modal-actions">
              <button className="dnd-button" onClick={handleNotificationSubmit}>
                Submit
              </button>
              <button className="dnd-button" onClick={handleCloseModal}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StatusFilter;

import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PipelineCardView.css";
import HistoryModal from "../../components/HistoryModal/HistoryModal";
import CallModal from "../../components/CallModal/CallModal";
import SubStatusModal from "../../components/SubStatusModal/SubStatusModal";
import InsuranceCompanyModal from "../../components/InsuranceCompanyModal/InsuranceCompanyModal";
import { createVehicleLogEntry } from "../../utils/createVehicleLogEntry";

export const PipelineCardView = ({ columns, setColumns }) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const [showSubStatusModal, setShowSubStatusModal] = useState(false);
  const [showInsuranceCompanyModal, setShowInsuranceCompanyModal] =
    useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [draggedItem, setDraggedItem] = useState(null);
  const [targetColumnId, setTargetColumnId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [selectedRegistration, setSelectedRegistration] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [serviceChecks, setServiceChecks] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);

  const toggleHistoryModal = (registration) => {
    setSelectedRegistration(registration);
    setShowHistoryModal((prev) => !prev);
  };

  const closeHistoryModal = () => {
    setShowHistoryModal(false);
    setSelectedRegistration(null);
  };

  const toggleCallModal = (contact) => {
    if (contact) {
      setSelectedContact(contact);
      setShowCallModal((prev) => !prev);
    }
  };

  const onDragStart = (event, itemId, columnId) => {
    event.dataTransfer.setData("itemId", itemId);
    event.dataTransfer.setData("originColumnId", columnId);
    const item = columns[columnId].items.find((item) => item.id === itemId);
    setDraggedItem({ ...item, columnId });
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = async (event, columnId) => {
    const itemId = event.dataTransfer.getData("itemId");
    const originColumnId = event.dataTransfer.getData("originColumnId");
  
    if (originColumnId && itemId && originColumnId !== columnId) {
      const item = columns[originColumnId].items.find(
        (item) => item.id.toString() === itemId
      );
  
      if (item) {
        const newStartItems = columns[originColumnId].items.filter(
          (item) => item.id.toString() !== itemId
        );
  
        let newColumnId = columnId;
  
        if (newColumnId === "won") {
          // Open the call modal instead of updating the status directly
          setSelectedContact(item);
          setShowCallModal(true);
          return;
        }
  
        if (newColumnId === "pendingInsurance") {
          // Extract details for the InsuranceCompanyModal
          setSelectedRegistration(item.registration);
          setSelectedJobId(item.id);
          setServiceChecks(item.service_checks);
          setFirstName(item.firstName);
          setlastName(item.lastName);
          setContactNumber(item.contactNumber);
          setTargetColumnId(newColumnId);
          setShowInsuranceCompanyModal(true);
        } else if (newColumnId === "pendingOther") {
          setTargetColumnId(newColumnId);
          setShowSubStatusModal(true);
        } else {
          const dealStatus =
            newColumnId === "new"
              ? "New"
              : newColumnId === "won"
              ? "Won"
              : newColumnId === "lost"
              ? "Lost"
              : "Pending";
  
          const job_status =
            newColumnId === "new"
              ? "Pending"
              : newColumnId === "won"
              ? "Approved"
              : newColumnId === "lost"
              ? "Lost"
              : "Pending";
  
          const updatedItem = {
            ...item,
            dealStatus,
            job_status,
            subStatus: "",
            insurance: item.insurance,
          };
  
          const newFinishItems = [...columns[newColumnId].items, updatedItem];
          setColumns((prev) => ({
            ...prev,
            [originColumnId]: {
              ...prev[originColumnId],
              items: newStartItems,
            },
            [newColumnId]: { ...prev[newColumnId], items: newFinishItems },
          }));
  
          try {
            await axios.post(
              `${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`,
              {
                job_id: item.id,
                dealStatus: updatedItem.dealStatus,
                job_status: updatedItem.job_status,
                subStatus: updatedItem.subStatus,
                insurance_broker: updatedItem.insurance_broker,
                service_checks: updatedItem.service_checks,
                insurance: updatedItem.insurance,
              }
            );
  
            await createVehicleLogEntry({
              registration_number: updatedItem.registration,
              user_id: userId,
              activity_title: "Moved to Column",
              activity_description: `Moved vehicle to ${dealStatus} status in ${newColumnId} column`,
            });
          } catch (error) {
            console.error(
              "Error updating dealStatus, job_status, and clearing subStatus:",
              error
            );
          }
        }
      } else {
        console.error("Item not found");
      }
    }
  };  

  const handleSubStatusChange = async (subStatus) => {
    if (draggedItem) {
      const newStartItems = columns[draggedItem.columnId].items.filter(
        (item) => item.id !== draggedItem.id
      );
      const updatedItem = {
        ...draggedItem,
        subStatus,
        dealStatus: "Pending",
        job_status: "Pending",
      };
      const newFinishItems = [...columns[targetColumnId].items, updatedItem];
      setColumns((prev) => ({
        ...prev,
        [draggedItem.columnId]: {
          ...prev[draggedItem.columnId],
          items: newStartItems,
        },
        [targetColumnId]: { ...prev[targetColumnId], items: newFinishItems },
      }));

      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`,
          {
            job_id: draggedItem.id,
            subStatus: subStatus,
            dealStatus: "Pending",
            job_status: "Pending",
          }
        );

        await createVehicleLogEntry({
          registration_number: draggedItem.registration,
          user_id: userId,
          activity_title: "Sub-Status Change",
          activity_description: `Changed sub-status to ${subStatus}`,
        });
      } catch (error) {
        console.error(
          "Error updating subStatus, dealStatus, and job_status:",
          error
        );
      }

      setShowSubStatusModal(false);
      setDraggedItem(null);
      setTargetColumnId(null);
    }
  };

  const handleInsuranceCompanyChange = async (insurance_broker) => {
    if (draggedItem) {
      const newStartItems = columns[draggedItem.columnId].items.filter(
        (item) => item.id !== draggedItem.id
      );
      const updatedItem = {
        ...draggedItem,
        insurance_broker,
        insurance: true,
        dealStatus: "Pending",
        job_status: "Pending",
        subStatus: "",
      };
      const newFinishItems = [...columns[targetColumnId].items, updatedItem];
      setColumns((prev) => ({
        ...prev,
        [draggedItem.columnId]: {
          ...prev[draggedItem.columnId],
          items: newStartItems,
        },
        [targetColumnId]: { ...prev[targetColumnId], items: newFinishItems },
      }));
      setShowInsuranceCompanyModal(false);
      setDraggedItem(null);
      setTargetColumnId(null);

      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`,
          {
            job_id: draggedItem.id,
            insurance_broker: insurance_broker,
            insurance: true,
            dealStatus: "Pending",
            job_status: "Pending",
          }
        );

        // Log the insurance change
        await createVehicleLogEntry({
          registration_number: draggedItem.registration,
          user_id: userId,
          activity_title: "Insurance Company Change",
          activity_description: `Changed insurance broker to ${insurance_broker}`,
        });
      } catch (error) {
        console.error(
          "Error updating insurance_broker, dealStatus, and job_status:",
          error
        );
      }
    }
  };

  const handleSave = async (updatedContact) => {
    console.log("Saving updated contact:", updatedContact); // Debugging

    const originColumnId = Object.keys(columns).find((columnId) =>
      columns[columnId].items.some((item) => item.id === updatedContact.id)
    );

    if (originColumnId) {
      const newStartItems = columns[originColumnId].items.filter(
        (item) => item.id !== updatedContact.id
      );

      let newColumnId = originColumnId;

      // Determine the new column based on the deal status
      if (updatedContact.dealStatus === "Pending") {
        newColumnId = updatedContact.insurance
          ? "pendingInsurance"
          : "pendingOther";
      } else if (
        updatedContact.dealStatus === "New" &&
        updatedContact.subStatus === "NA"
      ) {
        newColumnId = "new";
      } else if (updatedContact.dealStatus === "Won") {
        newColumnId = "won";
      } else if (updatedContact.dealStatus === "Lost") {
        newColumnId = "lost";
      }

      if (newColumnId === originColumnId) {
        setColumns((prev) => ({
          ...prev,
          [originColumnId]: {
            ...prev[originColumnId],
            items: newStartItems.map((item) =>
              item.id === updatedContact.id ? updatedContact : item
            ),
          },
        }));
      } else {
        const newFinishItems = [...columns[newColumnId].items, updatedContact];

        setColumns((prev) => ({
          ...prev,
          [originColumnId]: { ...prev[originColumnId], items: newStartItems },
        }));

        setTimeout(() => {
          setColumns((prev) => ({
            ...prev,
            [newColumnId]: { ...prev[newColumnId], items: newFinishItems },
          }));
        }, 0);
      }

      try {
        // Send updated contact details to the backend
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/contacts/update-details`,
          {
            job_id: updatedContact.id,
            firstName: updatedContact.firstName,
            lastName: updatedContact.lastName,
            contactNumber: updatedContact.contactNumber,
            email: updatedContact.emailAddress,
            dealStatus: updatedContact.dealStatus,
            job_status: updatedContact.job_status,
            clientType: updatedContact.clientType,
          }
        );

        await createVehicleLogEntry({
          registration_number: updatedContact.registration,
          user_id: userId,
          activity_title: "Contact Updated",
          activity_description: `Updated contact details and moved to ${newColumnId} column`,
        });
      } catch (error) {
        console.error("Error updating contact details:", error);
      }
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.user_id) {
      setUserId(userData.user_id);
    }
  }, []);

  return (
    <div className="card-view-container">
      <HistoryModal
        show={showHistoryModal}
        onClose={closeHistoryModal}
        registrationNumber={selectedRegistration}
        columns={columns}
      />
      <CallModal
        show={showCallModal}
        onClose={() => setShowCallModal(false)}
        contact={selectedContact}
        onSave={handleSave}
        columns={columns}
      />
      <SubStatusModal
        show={showSubStatusModal}
        onClose={() => setShowSubStatusModal(false)}
        onSubStatusChange={handleSubStatusChange}
      />
      <InsuranceCompanyModal
        show={showInsuranceCompanyModal}
        onClose={() => setShowInsuranceCompanyModal(false)}
        onInsuranceCompanyChange={handleInsuranceCompanyChange}
        registrationNumber={selectedRegistration}
        selectedJobId={selectedJobId}
        contactNumber={contactNumber}
        firstName={firstName}
        lastName={lastName}
        serviceChecks={serviceChecks}
      />

      {Object.entries(columns).map(([columnId, column]) => {
        return (
          <div
            key={columnId}
            className="card-column"
            onDragOver={onDragOver}
            onDrop={(event) => onDrop(event, columnId)}
          >
            <h3>{column.name}</h3>
            {[...column.items]
              .sort((a, b) => {
                if (a.is_express_service !== b.is_express_service) {
                  return b.is_express_service - a.is_express_service;
                }
                return new Date(a.created_at) - new Date(b.created_at);
              })
              .map((item) => (
                <div
                  key={`${columnId}-${item.id}`}
                  draggable={columnId !== "won" && columnId !== "lost"}
                  onDragStart={(event) =>
                    columnId !== "won" && columnId !== "lost"
                      ? onDragStart(event, item.id, columnId)
                      : null
                  }
                  className={`card ${
                    columnId === "won" || columnId === "lost"
                      ? "disabled-card"
                      : ""
                  }`}
                >
                  <div className="card-content">
                    <div className="card-title">{item.registration}</div>
                    <div className="card-info">
                      {item.firstName} {item.lastName}
                      <br />
                      {item.dealStatus}
                      <br />
                      {item.subStatus}
                    </div>
                    <div className="card-actions">
                      <button
                        className="card-button"
                        onClick={() => toggleCallModal(item)}
                      >
                        <span className="material-symbols-outlined">call</span>
                      </button>
                      <button
                        className="card-button"
                        onClick={() => toggleHistoryModal(item.registration)}
                      >
                        <span className="material-symbols-outlined">
                          history
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { useFetchData } from "../../utils/useFetchData";
import { useTechnicians } from "../../utils/useTechnicians";
import { useServiceAdvisors } from "../../utils/useServiceAdvisors";
import useHandleDealershipSubmit from "../../utils/useHandleDealershipSubmit";
import ModalDealershipGroup from "../ModalDealershipGroup/ModalDealershipGroup";
import ModalServiceAdvisor from "../ModalServiceAdvisors/ModalServiceAdvisors";

import ServiceAdvisorSelector from "./ServiceAdvisorSelector";
import DealershipGoalsForm from "./DealershipGoalsForm";
import TechnicianSelector from "./TechnicianSelector";
import GroupSelector from "./GroupSelector";
import "./ModalDealership.css";

const ModalDealership = ({
  isOpen,
  closeModal,
  title,
  formData,
  handleChange,
}) => {
  const {
    dealership_id,
    name,
    contact_number,
    technician,
    address,
    assigned_agent,
    dealership_group,
    vat_number,
    registration_number,
    service_agents,
  } = formData;

  // Fetch data using the custom hook
  const {
    callCenterAgents,
    technicians,
    dealershipGroups,
    serviceAdvisors,
    goals,
    fetchData,
    resetFetchFlag,
  } = useFetchData();

  const [localGoals, setLocalGoals] = useState(
    goals || {
      surveyed_cars: "",
      chips_goal: "",
      hlr_goal: "",
      pp_goal: "",
    }
  );

  // Directly pass `technician` to the TechnicianSelector without preprocessing
  const { selectedTechnicians, setSelectedTechnicians } = useTechnicians(
    technicians, // Full list of technicians
    technician, // Pass `technician` directly
    handleChange
  );

  const {
    selectedServiceAdvisors,
    setSelectedServiceAdvisors,
    showServiceAdvisorDropdown,
    handleServiceAdvisorDropdownToggle,
    handleServiceAdvisorChange,
  } = useServiceAdvisors(serviceAdvisors, service_agents, handleChange);

  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showServiceAdvisorModal, setShowServiceAdvisorModal] = useState(false);
  const [showTechnicianDropdown, setShowTechnicianDropdown] = useState(false);

  const { handleDealershipSubmit } = useHandleDealershipSubmit();

  useEffect(() => {
    if (isOpen) {
      fetchData(dealership_id);
    } else {
      resetFetchFlag();
    }
  }, [isOpen, dealership_id, fetchData, resetFetchFlag]);

  useEffect(() => {
    if (isOpen && goals && Object.keys(goals).length > 0) {
      setLocalGoals({
        surveyed_cars: goals.surveyed_cars || "",
        chips_goal: goals.chips_goal || "",
        hlr_goal: goals.hlr_goal || "",
        pp_goal: goals.pp_goal || "",
      });
    }
  }, [isOpen, goals]);

  const handleSubmit = (event) => {
    if (event?.preventDefault) event.preventDefault();

    // Log raw arrays at the start
    console.log("Raw Selected Technicians:", selectedTechnicians);
    console.log("Raw Selected Service Advisors:", selectedServiceAdvisors);

    // Format technician names as a single, deduplicated, comma-separated string
    const technicianNames =
      Array.isArray(selectedTechnicians) && selectedTechnicians.length
        ? [
            ...new Set(
              selectedTechnicians
                .flatMap(
                  (tech) => tech.id.split(",").map((name) => name.trim()) // Split into individual names and trim
                )
                .filter((name) => typeof name === "string" && name.trim()) // Ensure valid names
            ),
          ].join(", ") // Deduplicate and join into a single string
        : ""; // If no technicians are selected, return an empty string

    // Use or deduplicate service advisor IDs
    const advisorIds =
      Array.isArray(selectedServiceAdvisors) && selectedServiceAdvisors.length
        ? [
            ...new Set(
              selectedServiceAdvisors
                .flatMap(
                  (advisor) =>
                    Array.isArray(advisor.id)
                      ? advisor.id.map((a) => a.service_advisor_id) // Handle nested structure
                      : advisor.service_advisor_id || advisor.id // Extract valid IDs
                )
                .filter(Boolean) // Remove invalid entries
            ),
          ]
        : Array.isArray(service_agents)
        ? service_agents
        : JSON.parse(service_agents || "[]"); // Fallback to existing values

    // Log formatted data for debugging
    console.log("Submitting Data:");
    console.log("Formatted Technician Names:", technicianNames); // For debugging only
    console.log("Formatted Service Advisor IDs:", advisorIds);

    // Pass formatted data to handleDealershipSubmit
    handleDealershipSubmit({
      dealership_id,
      name,
      selectedServiceAdvisors: advisorIds,
      contact_number,
      technician: technicianNames,
      address,
      assigned_agent,
      dealership_group,
      vat_number,
      registration_number,
      localGoals,
      closeModal,
    });
  };

  const handleGoalsChange = (e) => {
    const { name, value } = e.target;
    setLocalGoals((prevGoals) => ({ ...prevGoals, [name]: value }));
  };

  const handleTechnicianChange = (technicianId, technicianName) => {
    setSelectedTechnicians((prevSelected) => {
      if (prevSelected.some((tech) => tech.id === technicianId)) {
        return prevSelected.filter((tech) => tech.id !== technicianId);
      }
      return [...prevSelected, { id: technicianId, name: technicianName }];
    });
  };

  const handleTechnicianDropdownToggle = () => {
    setShowTechnicianDropdown((prev) => !prev);
  };

  const handleAddServiceAdvisor = () => {
    setShowServiceAdvisorModal(true);
  };

  const handleCloseServiceAdvisorModal = () => {
    setShowServiceAdvisorModal(false);
  };

  const handleAddGroup = (newGroup) => {
    handleChange({
      target: { name: "dealership_group", value: newGroup.group_id },
    });
  };

  return (
    <>
      <div className="modal-dealership-overlay" onClick={closeModal}>
        <div
          className="modal-dealership-content"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-dealership-header">
            <h2 className="modal-dealership-title">{title}</h2>
            <button className="modal-dealership-close" onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className="modal-dealership-body">
            <div className="modal-dealership-columns">
              <div className="modal-dealership-column">
                <label>Dealership Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  className="styled-input"
                />
                <label>Dealership Goals</label>
                <DealershipGoalsForm
                  goals={localGoals}
                  handleGoalsChange={handleGoalsChange}
                />

                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={address}
                  onChange={handleChange}
                  className="styled-input"
                />

                <label>Contact Number</label>
                <input
                  type="text"
                  name="contact_number"
                  value={contact_number}
                  onChange={handleChange}
                  className="styled-input"
                />
                <label>VAT Number</label>
                <input
                  type="text"
                  name="vat_number"
                  value={vat_number}
                  onChange={handleChange}
                  className="styled-input"
                />
              </div>
              <div className="modal-dealership-column">
                <TechnicianSelector
                  technicians={technicians} // All technicians from the fetch
                  selectedTechnicians={technician} // Pass the raw technician prop
                  showTechnicianDropdown={showTechnicianDropdown}
                  handleTechnicianDropdownToggle={
                    handleTechnicianDropdownToggle
                  }
                  handleTechnicianChange={handleTechnicianChange}
                />

                <label>Assigned Call Center Agent</label>
                <div className="styled-dropdown">
                  <select
                    name="assigned_agent"
                    value={assigned_agent}
                    onChange={handleChange}
                    className="styled-input styled-select"
                    required
                  >
                    <option value="">Select an agent</option>
                    {callCenterAgents.map((agent) => (
                      <option key={agent.user_id} value={agent.user_id}>
                        {`${agent.user_first_name} ${agent.user_last_name}`}
                      </option>
                    ))}
                  </select>
                </div>
                <label>Service Advisors</label>
                <ServiceAdvisorSelector
                  serviceAdvisors={serviceAdvisors}
                  selectedServiceAdvisors={(() => {
                    try {
                      return Array.isArray(service_agents)
                        ? service_agents
                        : JSON.parse(service_agents || "[]"); // Default to empty array if parsing fails
                    } catch (error) {
                      console.error(
                        "Error parsing service_agents:",
                        service_agents,
                        error
                      );
                      return [];
                    }
                  })()}
                  handleServiceAdvisorChange={handleServiceAdvisorChange}
                  showServiceAdvisorDropdown={showServiceAdvisorDropdown}
                  handleServiceAdvisorDropdownToggle={
                    handleServiceAdvisorDropdownToggle
                  }
                  handleAddServiceAdvisor={handleAddServiceAdvisor}
                />

                <label>Dealership Group</label>
                <GroupSelector
                  dealershipGroups={dealershipGroups}
                  dealershipGroupValue={dealership_group}
                  handleGroupChange={handleChange}
                  handleAddGroupClick={() => setShowGroupModal(true)}
                />
                <label>Registration Number</label>
                <input
                  type="text"
                  name="registration_number"
                  value={registration_number}
                  onChange={handleChange}
                  className="styled-input"
                />
              </div>
            </div>
          </div>
          <div className="modal-dealership-footer">
            <button
              className="modal-dealership-submit-button"
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              className="modal-dealership-close-button"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <ModalDealershipGroup
        isOpen={showGroupModal}
        closeModal={() => setShowGroupModal(false)}
        onSubmit={handleAddGroup}
      />
      <ModalServiceAdvisor
        isOpen={showServiceAdvisorModal}
        closeModal={handleCloseServiceAdvisorModal}
      />
    </>
  );
};

export default ModalDealership;

export const fetchInsuranceCompanies = async () => {
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/insurance-companies`;
  
    try {
      const response = await fetch(API_URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error fetching insurance companies: ${response.statusText}`);
      }
  
      const data = await response.json();
      console.log('Insurance Companies Data: ', data)
      return data; // Assumes the backend response is a JSON array of companies
    } catch (error) {
      console.error("Failed to fetch insurance companies:", error);
      return [];
    }
  };
  
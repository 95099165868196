import React, { useState, useEffect, useMemo } from "react";
import DealDetails from "../DealDetails/DealDetails";
import ActionFilter from "../../components/ActionFilter/ActionFilter";
import StatusFilter from "../../components/StatusFilter/StatusFilter";
import Windscreen from "../Windscreen/Windscreen";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Notes from "../../components/Notes/Notes";
import ModalPortal from "../ModalPortal/ModalPortal";
import InsuranceCompanyModal from "../../components/InsuranceCompanyModal/InsuranceCompanyModal";
import { handleInsuranceCompanyChange } from "../../utils/handleInsuranceCompanyChange";
import fetchDealerships from "../../utils/fetchDealerships";
import { handleServiceChange } from "../../utils/handleServiceChange";
import { handleStatusChange } from "../../utils/handleStatusChange";
import ActivitiesModal from "../ActivitiesModal/ActivitiesModal";
import "./CallModal.css";

const CallModal = ({
  show,
  onClose,
  contact: initialContact,
  onSave,
  columns,
}) => {
  const [showInsuranceCompanyModal, setShowInsuranceCompanyModal] =
    useState(false);
  const [contact, setContact] = useState(initialContact || null);
  const [showActivitiesModal, setShowActivitiesModal] = useState(false);
  const [userId, setUserId] = useState(null);
  const [insuranceModalData, setInsuranceModalData] = useState({});
  const [matchedDealership, setMatchedDealership] = useState(null);
  const [lastFetchedDealership, setLastFetchedDealership] = useState(null);

  console.log("Contact being passed: ", contact);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.id) {
      setUserId(userData.id);
    } else {
      console.error("User ID not found in localStorage.");
    }
  }, []);

  const ensureUserId = () => {
    if (!userId) {
      console.error("User ID is still null. Check the component state.");
    }
    return userId;
  };

  const toggleInsuranceCompanyModal = (prePopulatedData = {}) => {
    setShowInsuranceCompanyModal((prev) => !prev);

    if (!showInsuranceCompanyModal) {
      setInsuranceModalData({
        registrationNumber: contact?.registration || "",
        selectedJobId: contact?.id || "",
        contactNumber: contact?.contactNumber || "",
        firstName: contact?.firstName || "",
        lastName: contact?.lastName || "",
        serviceChecks: contact?.service_checks || {},
        ...prePopulatedData,
      });
    }
  };

  const toggleActivitiesModal = () => {
    setShowActivitiesModal(!showActivitiesModal);
  };

  const handleInsuranceBrokerChange = (insurance_broker) => {
    const userIdToUse = ensureUserId();
    if (!userIdToUse) return;

    handleInsuranceCompanyChange({
      contact,
      setContact,
      insurance_broker,
      userId: userIdToUse,
      setShowInsuranceCompanyModal,
    });
  };

  const handleServiceOptionChange = (service, updatedService) => {
    const userIdToUse = ensureUserId();
    if (!userIdToUse) return;

    handleServiceChange({
      contact,
      setContact,
      service,
      updatedService,
      userId: userIdToUse,
    });
  };

  const handleContactStatusChange = (
    status,
    jobStatus = null,
    clientType = null
  ) => {
    const userIdToUse = ensureUserId();
    if (!userIdToUse) return;

    if (["EFT", "Insurance", "Account"].includes(status)) {
      clientType = status;
    }

    handleStatusChange({
      contact,
      setContact,
      status,
      jobStatus,
      userId: userIdToUse,
      clientType,
    });
  };

  useEffect(() => {
    if (show) {
      setContact(initialContact || null);
    } else {
      // Clear contact state when modal closes to prevent stale data issues
      setContact(null);
    }
  }, [show, initialContact]);

  useEffect(() => {
    const fetchAndMatchDealership = async () => {
      try {
        const dealerships = await fetchDealerships();
        const match = dealerships.find(
          (dealership) => dealership.name === contact?.dealership
        );

        if (match) {
          setMatchedDealership(match);
        } else {
          console.warn(
            "No matching dealership found for:",
            contact?.dealership
          );
        }
      } catch (error) {
        console.error("Error fetching and matching dealerships:", error);
      }
    };
    if (contact?.dealership && contact?.dealership !== lastFetchedDealership) {
      setLastFetchedDealership(contact?.dealership);
      fetchAndMatchDealership();
    }
  }, [contact?.dealership]);

  const handleSave = () => {
    const updatedContact = { ...contact };
    onSave(updatedContact);
    onClose();
  };

  const handleContactChange = (updatedContact) => {
    setContact(updatedContact);
  };

  const memoizedDealDetails = useMemo(() => {
    return contact ? (
      <DealDetails
        contact={contact}
        onServiceChange={handleServiceOptionChange}
        onContactChange={handleContactChange}
      />
    ) : null;
  }, [contact]);

  const memoizedActionFilter = useMemo(() => {
    return contact ? (
      <ActionFilter
        registrationNumber={contact.registration}
        columns={columns}
        wip={contact.wip}
        dealership={matchedDealership}
      />
    ) : null;
  }, [contact, columns]);

  const memoizedStatusFilter = useMemo(() => {
    return contact ? (
      <StatusFilter
        onInsuranceSelect={toggleInsuranceCompanyModal}
        onStatusChange={handleContactStatusChange}
        registrationNumber={contact?.registration || "N/A"}
        clientType={contact?.clientType || ""}
        userId={userId}
        jobId={contact?.id}
      />
    ) : null;
  }, [contact, userId]);

  const memoizedImagesAndNotes = useMemo(() => {
    return contact ? (
      <>
        <div className="images-container">
          <Windscreen registrationNumber={contact.registration} />
          <ImageGallery registrationNumber={contact.registration} />
        </div>
        <Notes dealId={contact.id} registrationNumber={contact.registration} />
      </>
    ) : null;
  }, [contact]);

  if (!show) return null;

  return (
    <ModalPortal>
      <div onClick={onClose}></div>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="call-modal">
          {memoizedActionFilter}
          {memoizedStatusFilter}
          {memoizedDealDetails}
          {memoizedImagesAndNotes}
          <div className="button-container">
            <button
              className="activities-button"
              onClick={toggleActivitiesModal}
            >
              View Activities
            </button>
            <button className="call-modal-save-button" onClick={handleSave}>
              Save
            </button>
            <button className="call-modal-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <InsuranceCompanyModal
        show={showInsuranceCompanyModal}
        onClose={toggleInsuranceCompanyModal}
        onInsuranceCompanyChange={handleInsuranceBrokerChange}
        registrationNumber={insuranceModalData.registrationNumber}
        selectedJobId={insuranceModalData.selectedJobId}
        contactNumber={insuranceModalData.contactNumber}
        firstName={insuranceModalData.firstName}
        lastName={insuranceModalData.lastName}
        serviceChecks={insuranceModalData.serviceChecks}
      />
      <ActivitiesModal
        show={showActivitiesModal}
        onClose={toggleActivitiesModal}
        registrationNumber={contact?.registration || ""}
      />
    </ModalPortal>
  );
};

export default CallModal;

import React, { useState } from "react";
import "./WidgetReport.css";
import DealershipGoals from "./DealershipGoals";
import AgentTargets from "./AgentTargets";

const WidgetReport = ({ userId, dateRange, agentData = {}, assignedDealerships }) => {
  const [selectedDealership, setSelectedDealership] = useState("all");
  const { jobCounts = {}, serviceCounts = {} } = agentData || {};

  return (
    <div className="widgets-column-container">
      <div className="widgets-left-column">
        <div className="agent-daily-figures-header">
          <h1>Agent Daily Figures</h1>
        </div>
        <AgentTargets
          userId={userId}
          serviceCounts={serviceCounts}
          jobCounts={jobCounts}
        />
      </div>

      <DealershipGoals
        userId={userId}
        selectedDealership={selectedDealership}
        setSelectedDealership={setSelectedDealership}
        assignedDealerships={assignedDealerships}
        dateRange={dateRange}
      />
    </div>
  );
};

export default WidgetReport;

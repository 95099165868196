import React, { useState, useEffect } from "react";
import { fetchUsersActivityStatus } from "../../utils/fetchUsersActivityStatus"; // Assuming this is your fetch utility
import { fetchAnalytics } from "../../utils/fetchAnalytics"; // New utility for fetching analytics data dynamically
import fetchDealerships from "../../utils/fetchDealerships"; // Fetch dealerships utility
import axios from "axios"; // For fetching users
import "./ManageReports.css";

const ManageReports = () => {
  const [activeTab, setActiveTab] = useState("Technicians");
  const [technicianActivityStatus, setTechnicianActivityStatus] = useState([]);
  const [callCenterActivityStatus, setCallCenterActivityStatus] = useState([]);
  const [technicianCards, setTechnicianCards] = useState({ generalPerformance: [], services: [] });
  const [callCenterCards, setCallCenterCards] = useState({ generalPerformance: [], services: [], additionalServices: [] });
  const [selectedCard, setSelectedCard] = useState(null); // State to store selected card
  const [filteredData, setFilteredData] = useState([]); // State for the filtered data
  const [selectedDateFilter, setSelectedDateFilter] = useState("Today"); // State for selected date filter
  const [dealerships, setDealerships] = useState([]); // State for dealerships
  const [users, setUsers] = useState([]); // State for users
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedDealership, setSelectedDealership] = useState(null);

  const handleUserChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected user ID:", selectedValue);
    setSelectedUser(selectedValue); // Update the selected user ID filter
  };  

  const handleDealershipChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected dealership:", selectedValue);
    setSelectedDealership(selectedValue); // Update the selected dealership filter
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch activity status data
        const activityData = await fetchUsersActivityStatus();
  
        // Filter based on user_role
        const technicians = activityData.filter(
          (user) => user.user_role === "technician" // Matching role correctly
        );
        const callCenterAgents = activityData.filter(
          (user) => user.user_role === "call center agent" // Matching role correctly
        );
  
        setTechnicianActivityStatus(technicians);
        setCallCenterActivityStatus(callCenterAgents);
  
        // Fetch analytics data dynamically based on selected filters
        const analyticsData = await fetchAnalytics(
          selectedDateFilter,
          selectedDealership,
          selectedUser,
          activeTab
        );
        setTechnicianCards(analyticsData.technicianCards || { generalPerformance: [], services: [] });
        setCallCenterCards(analyticsData.callCenterCards || { generalPerformance: [], services: [], additionalServices: [] });
  
        // Fetch dealerships
        const fetchedDealerships = await fetchDealerships();
        setDealerships(fetchedDealerships);
  
        // Fetch all users
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        const allUsers = response.data;
  
        // Filter users based on the active tab
        const filteredUsers = allUsers.filter((user) =>
          activeTab === "Technicians"
            ? user.user_role === "technician"
            : user.user_role === "call center agent"
        );
        setUsers(filteredUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [selectedDateFilter, selectedDealership, selectedUser, activeTab]); // Added `selectedUser` here
  
  
  // Placeholder for filter options
  const filters = {
    date: ["Today", "Last Week", "Last Month"],
  };

  // Handle card click to display relevant data
  const handleCardClick = (card) => {
    setSelectedCard(card);
    // Simulate setting filtered data (just placeholder values for now)
    setFilteredData([
      { name: "Item 1", detail: "Detail A" },
      { name: "Item 2", detail: "Detail B" },
    ]);
  };

  // Handle date filter change
  const handleDateFilterChange = (event) => {
    const selectedValue = event.target.value;
    console.log("Selected date filter:", selectedValue);
    setSelectedDateFilter(selectedValue); // Update the selected date filter
  };

  const renderCards = (cardSection) => {
    return cardSection.map((card, index) => (
      <div
        key={index}
        className="report-card"
        onClick={() => handleCardClick(card)}
      >
        <div className="card-number">{card.number}</div>
        <div className="card-title">{card.title}</div>
      </div>
    ));
  };

  const renderSection = (sectionTitle, cardSection) => {
    if (cardSection && cardSection.length > 0) {
      return (
        <div className="card-section">
          <h3 className="section-title">{sectionTitle}</h3>
          <div className="cards-wrapper">{renderCards(cardSection)}</div>
        </div>
      );
    }
    return null; // Don't render section if cardSection is empty or undefined
  };

  const renderContentForTab = () => {
    const cards =
      activeTab === "Technicians" ? technicianCards : callCenterCards;

    return (
      <>
        {renderSection("General Performance", cards.generalPerformance)}
        {renderSection("Services", cards.services)}
        {/* Conditionally render additional sections if they exist */}
        {renderSection("Additional Services", cards.additionalServices)}
      </>
    );
  };

  // Function to render the appropriate activity status based on the active tab
  const renderActivityStatus = () => {
    const currentActivityStatus =
      activeTab === "Technicians"
        ? technicianActivityStatus
        : callCenterActivityStatus;
    return currentActivityStatus.map((user, index) => (
      <div key={index} className={`user-status ${user.activity_status.toLowerCase()}`}>
        <span className={`status-dot ${user.activity_status.toLowerCase()}`}></span>
        <span className="user-name">{`${user.user_first_name} ${user.user_last_name}`}</span>
        <span className="status-text">{user.activity_status}</span>
      </div>
    ));
  };

  // Rendering the table with filtered data after a card click
  const renderTable = () => {
    if (!selectedCard) return null;

    return (
      <div className="data-table">
        <h3>{`Data for: ${selectedCard.title}`}</h3>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.detail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="ManageReports">
      <div className="main-reports-content">
        {/* Left Section: Tabs and Report Cards */}
        <div className="left-content">
          <h2 className="reports-heading">Reports</h2>
          <div className="tabs">
            <button
              className={`tab ${activeTab === "Technicians" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("Technicians")}
            >
              Technicians
            </button>
            <button
              className={`tab ${activeTab === "Call Center Agents" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("Call Center Agents")}
            >
              Call Center Agents
            </button>
          </div>

          {/* Filters */}
          <div className="filters">
            <select onChange={handleDateFilterChange}>
              <option value="">By Date Range</option>
              {filters.date.map((filter, index) => (
                <option key={index} value={filter}>
                  {filter}
                </option>
              ))}
            </select>
            <select onChange={handleUserChange}>
              <option value="">By User</option> {/* Default option */}
              {users.map((user, index) => (
                <option key={index} value={user.user_id}>
                  {`${user.user_first_name} ${user.user_last_name}`}
                </option>
              ))}
            </select>
            <select onChange={handleDealershipChange}>
              <option value="">By Dealership</option>
              {dealerships.map((dealership, index) => (
                <option key={index} value={dealership.name}>
                  {dealership.name}
                </option>
              ))}
            </select>
          </div>

          <div className="cards-container">{renderContentForTab()}</div>

          {/* Render the table if a card is selected */}
          {renderTable()}
        </div>

        {/* Right Section: Activity Status */}
        <div className="activity-status-section">
          <h2 className="activity-status-heading">Activity Status</h2>
          <div className="activity-status-card">
            <div className="activity-status-container">
              {renderActivityStatus()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReports;

import React, { useState, useEffect } from "react";
import {
  createServiceAdvisor,
  updateServiceAdvisorById,
  fetchAllServiceAdvisors,
  deleteServiceAdvisorById,
} from "../../utils/serviceAdvisors";
import "./ModalServiceAdvisors.css";

const ModalServiceAdvisor = ({ isOpen, closeModal, refreshServiceAdvisors }) => {
  const [formData, setFormData] = useState({
    service_advisor_id: null,
    name: "",
    email: "",
    tel: "",
  });

  const [serviceAdvisors, setServiceAdvisors] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false); // New state for delete confirmation modal

  // Fetch all service advisors to populate dropdown
  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const advisors = await fetchAllServiceAdvisors();
        setServiceAdvisors(advisors);
      } catch (error) {
        console.error("Error fetching service advisors:", error);
      }
    };

    if (isOpen) fetchAdvisors();
  }, [isOpen]);

  // Handle field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle dropdown selection for editing
  const handleAdvisorSelect = (advisorId) => {
    if (advisorId) {
      const selectedAdvisor = serviceAdvisors.find(
        (advisor) => advisor.service_advisor_id === parseInt(advisorId)
      );
      if (selectedAdvisor) {
        setFormData({
          service_advisor_id: selectedAdvisor.service_advisor_id,
          name: selectedAdvisor.service_advisor_name,
          email: selectedAdvisor.service_advisor_email,
          tel: selectedAdvisor.service_advisor_tel,
        });
        setIsEditing(true);
      }
    } else {
      // Reset form if no advisor is selected
      setFormData({ service_advisor_id: null, name: "", email: "", tel: "" });
      setIsEditing(false);
    }
  };

  // Handle form submission for adding or updating
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        await updateServiceAdvisorById(formData.service_advisor_id, {
          name: formData.name,
          email: formData.email,
          tel: formData.tel,
        });
      } else {
        await createServiceAdvisor({
          name: formData.name,
          email: formData.email,
          tel: formData.tel,
        });
      }

      // Notify parent to refresh service advisors
      if (refreshServiceAdvisors) refreshServiceAdvisors();

      closeModal();
    } catch (error) {
      console.error("Error saving service advisor:", error);
    }
  };

  // Handle delete confirmation
  const handleDelete = async () => {
    if (!formData.service_advisor_id) return;

    try {
      await deleteServiceAdvisorById(formData.service_advisor_id);
      if (refreshServiceAdvisors) refreshServiceAdvisors();
      closeModal();
    } catch (error) {
      console.error("Error deleting service advisor:", error);
    } finally {
      setShowDeleteConfirm(false);
    }
  };

  // Reset form when closing modal
  const resetForm = () => {
    setFormData({ service_advisor_id: null, name: "", email: "", tel: "" });
    setIsEditing(false);
  };

  if (!isOpen) return null;

  return (
    <div
      className="modal-service-advisor-overlay"
      onClick={() => {
        resetForm();
        closeModal();
      }}
    >
      <div
        className="modal-service-advisor-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2>{isEditing ? "Edit Service Advisor" : "Add Service Advisor"}</h2>
        <form onSubmit={handleSubmit}>
          <label>Select Service Advisor</label>
          <div className="styled-dropdown">
            <select
              className="styled-input styled-select"
              onChange={(e) => handleAdvisorSelect(e.target.value)}
              value={formData.service_advisor_id || ""}
            >
              <option value="">Select to Edit</option>
              {serviceAdvisors.map((advisor) => (
                <option
                  key={advisor.service_advisor_id}
                  value={advisor.service_advisor_id}
                >
                  {advisor.service_advisor_name}
                </option>
              ))}
            </select>
          </div>

          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="styled-input"
          />
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="styled-input"
          />
          <label>Telephone</label>
          <input
            type="text"
            name="tel"
            value={formData.tel}
            onChange={handleChange}
            className="styled-input"
          />

          <div className="button-group">
            <button type="submit" className="modal-service-advisor-submit">
              {isEditing ? "Save Changes" : "Confirm"}
            </button>
              <button
                type="button"
                className="modal-service-advisor-delete"
                onClick={() => setShowDeleteConfirm(true)}
              >
                Delete
              </button>
            <button
              type="button"
              className="modal-service-advisor-cancel"
              onClick={() => {
                resetForm();
                closeModal();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="delete-confirm-modal-overlay">
          <div className="delete-confirm-modal-content">
            <p>Are you sure you want to delete this service advisor?</p>
            <div className="button-group">
              <button
                type="button"
                className="confirm-delete-button"
                onClick={handleDelete}
              >
                Confirm
              </button>
              <button
                type="button"
                className="cancel-delete-button"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalServiceAdvisor;

import React, { useState, useEffect } from "react";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import ModalDealership from "../ModalDealership/ModalDealership";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import createDealership from "../../utils/createDealership";
import updateDealership from "../../utils/updateDealership";
import deleteDealership from "../../utils/deleteDealership";
import fetchDealerships from "../../utils/fetchDealerships";
import fetchServices from "../../utils/fetchServices";
import "./ManageDealerships.css";

const ManageDealerships = () => {
  const [dealerships, setDealerships] = useState([]);
  const [services, setServices] = useState([]);
  const [formData, setFormData] = useState({
    dealership_id: null,
    name: "",
    dealership_group: "",
    service_agents: "",
    contact_number: "",
    technician: "",
    address: "",
    assigned_agent: "",
    vat_number: "",
    registration_number: "",
    discounts: [],
  });
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deletingDealershipId, setDeletingDealershipId] = useState(null);

  // Fetch initial data function
  const fetchInitialData = async () => {
    try {
      console.log("Fetching initial data");
      const [dealershipData, serviceData] = await Promise.all([
        fetchDealerships(),
        fetchServices(),
      ]);
      console.log("Dealerships fetched:", dealershipData);
      console.log("Services fetched:", serviceData);
      setDealerships(dealershipData);
      setServices(serviceData);
    } catch (error) {
      console.error("Error fetching initial data:", error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (
      !formData.name.trim() ||
      !formData.dealership_group.trim() ||
      !formData.technician.trim() ||
      !formData.service_agents.trim() ||
      !formData.assigned_agent.trim()
    ) {
      alert(
        "Please ensure the Dealership Name, Dealership Group, Technician, Service Advisors, and Call Center Agent fields are filled out."
      );
      return;
    }

    // Preprocess formData to replace literal "[]" with an empty string
    const processedFormData = {
      ...formData,
      technician: formData.technician === "[]" ? "" : formData.technician,
      service_agents:
        formData.service_agents === "[]" ? "" : formData.service_agents,
      assigned_agent:
        formData.assigned_agent === "[]" ? "" : formData.assigned_agent,
    };

    try {
      if (formData.dealership_id) {
        await updateDealership(formData.dealership_id, processedFormData);
      } else {
        await createDealership(processedFormData);
      }
      await fetchInitialData(); // Refresh data
      setShowModal(false);
      resetFormData();
    } catch (error) {
      console.error("Error submitting dealership:", error);
    }
  };

  const resetFormData = () => {
    setFormData({
      dealership_id: null,
      name: "",
      dealership_group: "",
      service_agents: "",
      contact_number: "",
      technician: "",
      address: "",
      assigned_agent: "",
      vat_number: "",
      registration_number: "",
      discounts: [],
    });
  };

  const handleDelete = async () => {
    try {
      await deleteDealership(deletingDealershipId);
      await fetchInitialData(); // Refresh data
      setShowConfirmationModal(false);
    } catch (error) {
      console.error("Error deleting dealership:", error);
    }
  };

  const handleOpenModal = () => {
    const initialFormData = {
      dealership_id: null,
      name: "",
      dealership_group: "",
      service_agents: "",
      contact_number: "",
      technician: "",
      address: "",
      assigned_agent: "",
      vat_number: "",
      registration_number: "",
      discounts: services.map((service) => ({
        service_id: service.service_id,
        service_name: service.service_name,
        trade_price_excl: service.trade_price_excl,
        trade_price_incl: service.trade_price_incl,
        retail_price_excl: service.retail_price_excl,
        retail_price_incl: service.retail_price_incl,
        gross_profit_excl: service.gross_profit_excl,
        discount_percentage: 0,
      })),
    };
    console.log("Initial formData:", initialFormData);
    setFormData(initialFormData);
    setShowModal(true);
  };

  const handleRowClick = (dealership) => {
    // Correctly process technician and service_agents fields
    const processedTechnician = dealership.technician
      ? Array.isArray(dealership.technician)
        ? dealership.technician.join(", ")
        : dealership.technician
      : "";

    const processedServiceAgents = dealership.service_agents
      ? Array.isArray(dealership.service_agents)
        ? dealership.service_agents.join(",")
        : dealership.service_agents
      : "";

    setFormData({
      ...dealership,
      vat_number: dealership.vat_number || "",
      registration_number: dealership.registration_number || "",
      technician: processedTechnician || "",
      service_agents: processedServiceAgents,
    });

    setShowModal(true);
  };

  const handleOpenConfirmationModal = (dealership_id) => {
    setDeletingDealershipId(dealership_id);
    setShowConfirmationModal(true);
  };

  const handleCloseModal = async () => {
    await fetchInitialData(); // Refresh data
    setShowModal(false);
  };

  return (
    <div className="ManageDealerships">
      <h2>Manage Dealerships</h2>
      <div className="icon-container" onClick={handleOpenModal}>
        <FaPlusCircle className="fapluscircle-icon" />
      </div>
      {showModal && (
        <ModalDealership
          isOpen={showModal}
          closeModal={handleCloseModal}
          title={
            formData.dealership_id ? "Edit Dealership" : "Add New Dealership"
          }
          formData={formData}
          handleChange={handleChange}
          onSubmit={handleSubmit}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          isOpen={showConfirmationModal}
          message="Are you sure you want to delete this dealership?"
          onConfirm={handleDelete}
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}
      <table>
        <thead>
          <tr>
            <th>Dealership Name</th>
            <th>Dealership Group</th>
            <th>Service Advisors</th>
            <th>Contact Number</th>
            <th>Technician</th>
            <th>Call Center Agent</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {dealerships.map((dealership) => (
            <tr
              key={dealership.dealership_id}
              onClick={() => handleRowClick(dealership)}
            >
              <td>{dealership.name}</td>
              <td>{dealership.dealership_group_name || "Unassigned"}</td>
              <td>{dealership.service_advisors}</td>
              <td>{dealership.contact_number}</td>
              <td>{dealership.technician}</td>
              <td>{dealership.agent_name || "Unassigned"}</td>
              <td>{dealership.address}</td>
              <td className="actions-cell">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenConfirmationModal(dealership.dealership_id);
                  }}
                  className="delete-button"
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageDealerships;

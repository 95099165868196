// fetchJobCountsAndServioces.js
import axios from "axios";

export async function fetchJobCountsAndServices(dateRange, userId) {
  try {
    const [jobCountsResponse, serviceCountsResponse] = await Promise.all([
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/jobs/counts`, {
        params: { user_id: userId, startDate: dateRange.startDate, endDate: dateRange.endDate }
      }),
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/services/service-check-counts`, {
        params: { userId, startDate: dateRange.startDate, endDate: dateRange.endDate }
      })
    ]);

    return {
      jobCounts: jobCountsResponse.data,
      serviceCounts: serviceCountsResponse.data.data,
    };
  } catch (error) {
    console.error("Error fetching job counts and service checks:", error);
    return {
      jobCounts: {
        pendingDeals: 0,
        wonDeals: 0,
        lostDeals: 0,
        newDeals: 0,
        assignedTasks: 0,
      },
      serviceCounts: {
        total_chips: 0,
        total_replace_jobs: 0,
        total_polish_jobs: 0,
        total_headlight_repairs: 0,
        total_surveyed_cars: 0,
      },
    };
  }
}

import React, { useState, useEffect } from "react";
import { fetchAssignedDealerships } from "../../utils/fetchAssignedDealerships";
import { fetchJobCountsByAgentAndDealership } from "../../utils/fetchJobCountsByAgentAndDealership";

const DealershipGoals = ({ userId, selectedDealership, setSelectedDealership, dateRange }) => {
  const [dealershipGoals, setDealershipGoals] = useState([]);
  const [totals, setTotals] = useState({
    chips_goal: 0,
    hlr_goal: 0,
    pp_goal: 0,
    surveyed_cars: 0,
  });

  const [actuals, setActuals] = useState({
    headlight: 0,
    windscreen: 0,
    polish: 0,
    surveyedCars: 0,
  });

  useEffect(() => {
    const fetchDealershipData = async () => {
      if (!userId) return;
      try {
        const data = await fetchAssignedDealerships(userId);
        console.log("Fetched dealership goals data:", data);

        if (Array.isArray(data) && data.length > 0) {
          setDealershipGoals(data);
        } else {
          console.warn("No dealership goals data found.");
        }
      } catch (error) {
        console.error("Error fetching dealership data:", error);
      }
    };

    fetchDealershipData();
  }, [userId]);

  useEffect(() => {
    const fetchActuals = async () => {
      if (!userId || !dateRange?.startDate || !dateRange?.endDate || !selectedDealership) return;

      // Find dealership name based on selected ID
      const selectedDealershipData = dealershipGoals.find(
        (goal) => goal.dealership_id === parseInt(selectedDealership, 10)
      );

      const dealershipName = selectedDealership === "all" ? "all" : selectedDealershipData?.dealership_name || "";

      try {
        const counts = await fetchJobCountsByAgentAndDealership(userId, dealershipName, dateRange);
        console.log("Fetched actual job counts:", counts);

        setActuals({
          headlight: counts?.serviceCounts?.headlight || 0,
          windscreen: counts?.serviceCounts?.windscreen || 0,
          polish: counts?.serviceCounts?.polish || 0,
          surveyedCars: counts?.surveyedCars || 0,
        });
      } catch (error) {
        console.error("Error fetching actual job counts:", error);
      }
    };

    fetchActuals();
  }, [selectedDealership, userId, dateRange, dealershipGoals]);

  useEffect(() => {
    if (selectedDealership === "all") {
      // Sum up goals for all dealerships when "All Dealerships" is selected
      const calculatedTotals = dealershipGoals.reduce(
        (acc, goal) => ({
          chips_goal: acc.chips_goal + (goal?.chips_goal ?? 0),
          hlr_goal: acc.hlr_goal + (goal?.hlr_goal ?? 0),
          pp_goal: acc.pp_goal + (goal?.pp_goal ?? 0),
          surveyed_cars: acc.surveyed_cars + (goal?.surveyed_cars ?? 0),
        }),
        { chips_goal: 0, hlr_goal: 0, pp_goal: 0, surveyed_cars: 0 }
      );
      setTotals(calculatedTotals);
    } else {
      // Find the selected dealership and update totals accordingly
      const selectedData = dealershipGoals.find(
        (goal) => goal.dealership_id === parseInt(selectedDealership, 10)
      );

      if (selectedData) {
        setTotals({
          chips_goal: selectedData.chips_goal || 0,
          hlr_goal: selectedData.hlr_goal || 0,
          pp_goal: selectedData.pp_goal || 0,
          surveyed_cars: selectedData.surveyed_cars || 0,
        });
      } else {
        setTotals({ chips_goal: 0, hlr_goal: 0, pp_goal: 0, surveyed_cars: 0 });
      }
    }
  }, [selectedDealership, dealershipGoals]);

  return (
    <div className="widgets-right-column">
      <div className="dealership-widgets-header">
        <h1>Dealership Daily Goals</h1>
        <select onChange={(e) => setSelectedDealership(e.target.value)} value={selectedDealership}>
          <option value="all">All Dealerships</option>
          {dealershipGoals.length > 0 ? (
            dealershipGoals.map((goal) => (
              <option key={goal.dealership_id} value={goal.dealership_id}>
                {goal.dealership_name || `Dealership ${goal.dealership_id}`}
              </option>
            ))
          ) : (
            <option disabled>No Dealerships Assigned</option>
          )}
        </select>
      </div>

      <div className="widget-report">
        <div className="widget">
          <div className="widget-text">
            <h3>Surveyed</h3>
            <h4>
              {actuals.surveyedCars} / {totals.surveyed_cars}
            </h4>
          </div>
        </div>
        <div className="widget">
          <div className="widget-text">
            <h3>Chips</h3>
            <h4>
              {actuals.windscreen} / {totals.chips_goal}
            </h4>
          </div>
        </div>
        <div className="widget">
          <div className="widget-text">
            <h3>HLR</h3>
            <h4>
              {actuals.headlight} / {totals.hlr_goal}
            </h4>
          </div>
        </div>
        <div className="widget">
          <div className="widget-text">
            <h3>Paint Protect</h3>
            <h4>
              {actuals.polish} / {totals.pp_goal}
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealershipGoals;

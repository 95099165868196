import axios from "axios";
import { createVehicleLogEntry } from "./createVehicleLogEntry";

/**
 * Handles the status change and updates relevant job details.
 * @param {Object} params - The function parameters.
 * @param {Object} params.contact - Current contact details.
 * @param {Function} params.setContact - Function to update the contact state.
 * @param {string} params.status - The new status to set.
 * @param {string|null} params.jobStatus - An optional job status to set as a subStatus.
 * @param {string|null} params.clientType - The client type to set (e.g., "Account", "EFT", "Insurance").
 * @param {string} params.userId - The user ID performing the operation.
 */
export const handleStatusChange = async ({
  contact,
  setContact,
  status,
  jobStatus = null,
  clientType = null,
  userId,
}) => {
  if (!userId) {
    console.error("User ID is required to perform this action.");
    return;
  }
  const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/jobs/update-details`;

  let updatedContact = { ...contact };
  let requestBody = {
    job_id: contact.id,
    clientType,
    wip: contact.wip,
  };

  if (status === "Account" || status === "EFT") {
    updatedContact.dealStatus = "Won";
    updatedContact.job_status = "Approved";
    updatedContact.subStatus = "";
    updatedContact.clientType = clientType;

    requestBody = {
      ...requestBody,
      dealStatus: "Won",
      job_status: "Approved",
      subStatus: "",
    };
  } else if (status === "Insurance") {
    updatedContact.dealStatus = "Pending";
    updatedContact.job_status = "Pending";
    updatedContact.subStatus = "";
    updatedContact.clientType = clientType;

    requestBody = {
      ...requestBody,
      dealStatus: "Pending",
      job_status: "Pending",
      subStatus: "",
    };
  } else if (status === "DNC" || status === "No") {
    updatedContact.dealStatus = "Lost";
    updatedContact.job_status = "Lost";
    updatedContact.subStatus = "";
    updatedContact.clientType = null;

    requestBody = {
      ...requestBody,
      dealStatus: "Lost",
      job_status: "Lost",
      subStatus: "",
      clientType: null,
    };
  } else {
    updatedContact.dealStatus = status;
    updatedContact.subStatus = jobStatus || "";
    updatedContact.clientType = null;

    requestBody = {
      ...requestBody,
      dealStatus: status,
      job_status: jobStatus || "Pending",
      subStatus: jobStatus || "",
      clientType: null,
    };
  }
  setContact(updatedContact);

  try {
    const response = await axios.post(apiUrl, requestBody);

    await createVehicleLogEntry({
      registration_number: updatedContact.registration,
      user_id: userId,
      activity_title: "Status Updated",
      activity_description: `Changed status to ${updatedContact.dealStatus} with client type ${clientType || "N/A"}`,
    });
  } catch (error) {
    console.error("Error updating dealStatus, job_status, and clientType:", error);
  }
};

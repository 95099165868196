import axios from "axios";

/**
 * Fetch job counts for a specific agent and dealership.
 * @param {number} agentId - The ID of the assigned agent.
 * @param {number|string} dealershipName - The name of the dealership or "all".
 * @param {Object} dateRange - The date range with startDate and endDate.
 * @returns {Promise<Object>} - The counts of service checks and surveyed cars.
 */
export const fetchJobCountsByAgentAndDealership = async (agentId, dealershipName, dateRange) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/jobs/counts/${agentId}/${dealershipName}`,
      {
        params: {
          startDate: dateRange.startDate, // Add startDate to query params
          endDate: dateRange.endDate,     // Add endDate to query params
        },
      }
    );
    console.log('Dealership data response: ', response)
    return response.data.data;
  } catch (error) {
    console.error("Error fetching job counts:", error);
    throw error;
  }
};

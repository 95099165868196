import axios from "axios";

/**
 * Fetch analytics data from the backend.
 * @param {string} dateFilter - The selected date filter.
 * @param {string|null} dealership - The selected dealership.
 * @returns {Promise<Object>} - Resolves to the analytics data.
 */
export const fetchAnalytics = async (dateFilter, dealership = null, user = null, activeTab = null) => {
    try {
      console.log("Sending query parameters:", { dateFilter, dealership, user, activeTab });
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/analytics`, {
        params: { dateFilter, dealership, user, activeTab },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching analytics data:", error);
      throw new Error("Failed to fetch analytics data.");
    }
  };

import React, { useState, useEffect } from "react";
import { format, startOfDay, endOfDay } from "date-fns";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import WidgetReport from "../components/WidgetReport/WidgetReport";
import TaskDiaryTable from "../components/TaskDiaryTable/TaskDiaryTable";
import RangeDatePicker from "../components/CustomDatePicker/CustomDatePicker";
import GoalsModal from "../components/GoalsModal/GoalsModal";
import { updateActivityStatus } from "../utils/updateActivityStatus";
import { fetchJobCountsAndServices } from "../utils/fetchJobCountsAndServices";
import "./Dashboard.css";

const Dashboard = () => {
  const [isGoalsModalOpen, setGoalsModalOpen] = useState(false);
  const [assignedDealerships, setAssignedDealerships] = useState([]);
  const [agentData, setAgentData] = useState({
    jobCounts: {
      pendingDeals: 0,
      wonDeals: 0,
      lostDeals: 0,
      newDeals: 0,
      assignedTasks: 0,
    },
    serviceCounts: {
      total_chips: 0,
      total_replace_jobs: 0,
      total_polish_jobs: 0,
      total_headlight_repairs: 0,
      total_surveyed_cars: 0,
    },
  });

  const [dateRange, setDateRange] = useState({
    startDate: format(startOfDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
    endDate: format(endOfDay(new Date()), "yyyy-MM-dd HH:mm:ss"),
  });

  console.log('Start Date: ', dateRange.startDate)
  console.log('End Date: ', dateRange.endDate)

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user && user.id) {
      fetchAgentData();
    }
  }, [dateRange, user?.id]);

  const fetchAgentData = async () => {
    if (user && user.id) {
      const data = await fetchJobCountsAndServices(dateRange, user.id);
      console.log("Agent data: ", data);
      setAgentData(data);
    }
  };

  useEffect(() => {
    const dealerships = JSON.parse(localStorage.getItem("assignedDealerships")) || [];
    setAssignedDealerships(dealerships);
  }, []);

  useEffect(() => {
    if (user && user.id) {
      updateActivityStatus(user.id, "online");
      return () => updateActivityStatus(user.id, "offline");
    }
  }, [user]);

  return (
    <div className="dashboard">
      <Header />
      <div className="dashboard-content">
        <Sidebar />
        <main className="dashboard-main-content">
          <div className="dashboard-page-header">
            <RangeDatePicker setDateRange={setDateRange} />
            <button className="how-am-i-doing" onClick={() => setGoalsModalOpen(true)}>
              How Am I Doing
            </button>
            <GoalsModal isOpen={isGoalsModalOpen} onClose={() => setGoalsModalOpen(false)} />
          </div>
          <WidgetReport 
            userId={user.id} 
            dateRange={dateRange} 
            agentData={agentData} 
            assignedDealerships={assignedDealerships} 
          />
          <TaskDiaryTable />
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
